var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('InfoBanner',{attrs:{"module_name":this.module_name}}),_c('PageTitle',{attrs:{"hide_btns":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Счета на оплату ")]},proxy:true}])}),(_vm.info_message())?_c('InfoAlert',{staticClass:"mb-5",attrs:{"is_dark":""},scopedSlots:_vm._u([{key:"alert_text",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.info_message())}})]},proxy:true}],null,false,631939790)}):_vm._e(),_c('v-card',{class:_vm.$style.card},[_c('FiltersComponent',{on:{"filter_update":_vm.filter_update},scopedSlots:_vm._u([{key:"btn-outer",fn:function(){return [_c('v-btn',{class:[_vm.$style.btnOuter , 'mb-0'],attrs:{"text":""},on:{"click":function($event){_vm.cols_dialog = true}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-tune")]),_c('span',[_vm._v("Настройка полей")])],1)]},proxy:true}])}),_vm._l((_vm.filtered_cols),function(header,idx){return _c('ResizeCols',{key:idx,attrs:{"header":header,"storage_name":_vm.cols_storage_name}})}),_c('Table',{class:_vm.$style.table,attrs:{"headers":_vm.filtered_cols,"items":_vm.orders_invoices,"options":_vm.table_options,"custom-sort":_vm.prevent_sort,"loading":_vm.orders_invoices_loading,"no-data-text":"Счета, удовлетворяющие выбранным параметрам, отсутствуют","hide-default-footer":""},on:{"update:options":function($event){_vm.table_options=$event}},scopedSlots:_vm._u([{key:"item.order_id",fn:function(ref){
var item = ref.item;
return [_c('td',{class:_vm.row_class(item)},[_c('div',{class:{'d-flex align-center': !_vm.is_mobile}},[(item.order_number || item.order_id)?_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(item.order_number || item.order_id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(item.order_number || item.order_id)+" ")])],1)])]}},{key:"item.currency",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2",class:_vm.row_class(item)},[_vm._v(" "+_vm._s(_vm.item_currency(item))+" ")])]}},{key:"item.total_price",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2",class:_vm.row_class(item)},[_vm._v(" "+_vm._s(_vm._f("currency_rub")(item.total_price))+" ")])]}},{key:"item.organization",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2",class:_vm.row_class(item)},[_vm._v(" "+_vm._s(item.organization)+" ")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2",class:_vm.row_class(item)},[_vm._v(" "+_vm._s(_vm._f("date")(item.created_at))+" ")])]}},{key:"item.payment_date",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2",class:_vm.row_class(item)},[_vm._v(" "+_vm._s(_vm._f("date")(item.payment_at))+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2",class:[_vm.$style.textWrap, _vm.row_class(item)]},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2",class:_vm.row_class(item)},[_vm._v(" "+_vm._s(_vm._f("date")(item.updated_at))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2",class:_vm.row_class(item)},[_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"#000","href":item.path,"target":"_blank","disabled":!_vm.is_pdf(item)},on:{"click":function($event){$event.stopPropagation();return _vm.send_read(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-printer-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Печать")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('DownloadBtn',_vm._g(_vm._b({attrs:{"src":item.path},on:{"click":function($event){$event.stopPropagation();return _vm.send_read(item)}}},'DownloadBtn',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Скачать")])])],1)])]}},{key:"footer",fn:function(){return [_c('Pagination',{attrs:{"pagination_props":_vm.pagination_props},on:{"change_page":function($event){return _vm.upd_pagination('current_page', $event)},"page_size_changed":function($event){return _vm.upd_pagination('limit', $event)}}})]},proxy:true}],null,true)})],2),_c('CustomColsDialog',{attrs:{"cols":_vm.orders_invoices_cols,"cols_list":_vm.orders_invoices_cols,"storage_name":_vm.cols_storage_name,"default_cols":_vm.cols_default},on:{"update:cols_list":function($event){_vm.orders_invoices_cols=$event},"save_cols":function($event){_vm.cols_dialog = false},"cancel_dialog":function($event){_vm.cols_dialog = false},"close":function($event){_vm.cols_dialog = false},"default_limit":_vm.default_limit},model:{value:(_vm.cols_dialog),callback:function ($$v) {_vm.cols_dialog=$$v},expression:"cols_dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }